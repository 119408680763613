import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { ImBooks } from 'react-icons/im';
import { MdCollections } from 'react-icons/md';
import { BsFillCollectionFill } from 'react-icons/bs';
import { IoMdReturnLeft, IoIosPhotos } from 'react-icons/io';

const Sidebar = ({ collapsed, toggled, handleToggleSidebar }) => {
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            marginTop: '70px',
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          Categories
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<IoIosPhotos size={20}/>}> All</MenuItem>

          <MenuItem
            icon={<ImBooks size={20}/>}
            suffix={<span className="badge red">New</span>}
          >
            Books
          </MenuItem>

          <MenuItem icon={<MdCollections size={20}/>}> Art</MenuItem>

        </Menu>
        <Menu iconShape="circle">
          <SubMenu
            suffix={<span className="badge red">New</span>}
            title="Collections"
            icon={<BsFillCollectionFill size={20} />}
          >
            <MenuItem>PERSONAL Impulses</MenuItem>
            <MenuItem>Matilda & Mathiew</MenuItem>
            <MenuItem suffix={<span className="badge red">New</span>}>Sketches and Searches</MenuItem>
          </SubMenu>

        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: 'center' }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a
            href="https://silviupreda.art/"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <IoMdReturnLeft size={20}/>
            <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              Back to Website
            </span>
          </a>
          
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;