import React, { useState, useEffect } from 'react';
import { commerce } from './lib/commerce';
import { Products, Navbar, Sidebar, Cart, Checkout } from './components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './components/Sidebar/style.scss'
import { FaBars } from 'react-icons/fa';

// TODO: include shipping price

const App = () => {

    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [order, setOrder] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [sidebarVisibility, setSidebarVisibility] = useState(true);

    const fetchProducts = async () => {
        const { data } = await commerce.products.list();
    
        setProducts(data);
    }

    const fetchCart = async () => {
        const cart = await commerce.cart.retrieve();

        setCart(cart);
    }

    const handleAddToCart = async (productId, quantity) => {
        const { cart } = await commerce.cart.add(productId, quantity);
        
        setCart(cart);
    }

    const handleUpdateCartQty = async (productId, quantity) => {
        const { cart } = await commerce.cart.update(productId, {quantity});

        setCart(cart);
    }

    const handleRemoveFromCart = async (productId) => {
        const { cart } = await commerce.cart.remove(productId);
    
        setCart(cart);
    };

    const handleEmptyCart = async () => {
        const { cart } = await commerce.cart.empty();
    
        setCart(cart);
    };

    const refreshCart = async () => {
        const newCart = await commerce.cart.refresh();
        
        setCart(newCart);
    }

    const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
        try {
            const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);

            setOrder(incomingOrder);
            refreshCart();
        } catch (error) {
            setErrorMessage(error.data.error.message);
        }
    }

    useEffect(() => {
        fetchProducts();
        fetchCart();
    }, []);

    //Sidebar

    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
  
    const handleCollapsedChange = (checked) => {
      setCollapsed(checked);
    };

  
    const handleToggleSidebar = () => {
      setToggled(!toggled);
    };

    return (

      <div className={`app ${toggled ? 'toggled' : ''}`}>
        {sidebarVisibility && <Sidebar
          collapsed={collapsed}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        /> }
        <Router>
              <Navbar totalItems={cart.total_items} handleToggleSidebar={handleToggleSidebar}/>
          <Routes>
              <Route path="/" element={ <Products products={products} onAddToCart={handleAddToCart}/> } />
              <Route path="/cart" element={ <Cart cart={cart} handleUpdateCartQty={handleUpdateCartQty} handleRemoveFromCart={handleRemoveFromCart} handleEmptyCart={handleEmptyCart} /> } />
              <Route path="/checkout" element={ <Checkout cart={cart} order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage} /> } />
          </Routes>
        </Router>
      </div>

    )
}

export default App
