import React, { useState, useEffect } from 'react'
import { Card, CardMedia, CardContent, CardActions, Typography, IconButton } from '@material-ui/core';
import { AddShoppingCart } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom'; 

import useStyles from './styles';

const Product = ({ product, onAddToCart }) => {
    const classes = useStyles();

    const [available, setAvailable] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if((6 - product.inventory.available) > 5) {
            setAvailable(false);
        }
    
    }, []);

    const goToProductPage = () => {
        navigate("../cart", { replace: true });
    }

    console.log(product);

    return (
            <Card className={classes.root} onClick={goToProductPage}>
                <CardMedia className={classes.media} image={product.image.url} title={product.name}/>
                <CardContent>
                    <div className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="h2">
                        {product.price.formatted_with_symbol}
                    </Typography>
                    </div>
                    <Typography dangerouslySetInnerHTML={{ __html: product.description }} variant='body2' color='textSecondary'/>
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                    <Typography variant='h6' style={{padding: '5px'}}>
                        {!available ? "Sold out" : `Edition: ${6 - product.inventory.available} / 5` } 
                    </Typography>
                    <IconButton aria-label="Add to Cart" onClick={() => onAddToCart(product.id, 1)}>
                        <AddShoppingCart />
                    </IconButton>
                </CardActions>
            </Card>
    )
}

export default Product
